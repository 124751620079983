import translations from "../i18n/translations.json";

let currentLang = '';

export default (lang = 'en') => {
    if (lang === currentLang) return;

    currentLang = lang;
    const langListNodes = document.querySelectorAll('[data-lang]');
    const textNodes = document.querySelectorAll('[data-i18n-key]');
    textNodes.forEach(node => {
        node.innerHTML = translations[lang][node.dataset['i18nKey']] || '';
    });
    langListNodes.forEach(node => {
        if (node.dataset.lang === lang) {
            node.classList.add('active');
        } else node.classList.remove('active');
    });
};
