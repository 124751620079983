import { gsap } from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

const animationMap = {
  leftSlide: {
      from: {
          x: -700,
          opacity: 0,
      },
      to: {
          x: 0,
          opacity: 1,
      }
  },
  rightSlide: {
      from: {
          x: 700,
          opacity: 0,
      },
      to: {
          x: 0,
          opacity: 1,
      }
  },
  topSlide: {
      from: {
          y: 100,
          opacity: 0,
      },
      to: {
          y: 0,
          opacity: 1,
          duration: 0.8,
          delay: 0.3,
          scrollTrigger: {
              start: 'top bottom+=100'
          }
      }
  },

   scrubView: {
      from: {
          scale: 1,
      },
      to: {
          scale: 1.1,
          scrollTrigger: {
              scrub: true
          }
      }
   }
};

export default () => {
    gsap.utils.toArray('[data-animate]').forEach(section => {
        const options = animationMap[section.dataset.animate];
        gsap.fromTo(section,options.from,{
            duration: 0.6,
            ...options.to,
            scrollTrigger: {
                trigger: section,
                start: 'top bottom-=100px',
                ...options.to.scrollTrigger
            },
        });
    });
};
