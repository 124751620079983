import setupAnimation from './animation';
import i18n from './i18n';

import '../styles/index.scss';

if (process.env.NODE_ENV === 'development') {
  require('../index.html');
}

i18n();
setupAnimation();

function detectMob() {
  const toMatch = [
    /Android/i,
    /webOS/i,
    /iPhone/i,
    /iPad/i,
    /iPod/i,
    /BlackBerry/i,
    /Windows Phone/i
  ];

  return toMatch.some((toMatchItem) => {
    return navigator.userAgent.match(toMatchItem);
  });
}

document.querySelectorAll('.lang__list').forEach(item => {
  item.addEventListener('click', (e) => {
    e.currentTarget.classList.toggle('open');
  });
  item.addEventListener('mouseover', (e) => {
    if (detectMob()) return;
    e.currentTarget.classList.add('open');
  });
  item.addEventListener('mouseout', (e) => {
    if (detectMob()) return;
    e.currentTarget.classList.remove('open');
  });
});
document.querySelectorAll('.lang__item').forEach(item => {
  item.addEventListener('click', (e) => {
    i18n(item.dataset.lang);
  });
});
